import apiClient from "@/api/api-client";

export const getFiles = async (filters) => {
  const { data } = await apiClient.get("/files", {
    params: filters,
  });
  return data;
};

export const getNewFiles = async (file_ids) => {
  const { data } = await apiClient.post("/files/import", {
    file_ids,
  });
  return data;
};

export const getPreviewFiles = async (params) => {
  const { data } = await apiClient.get("/files/import", {
    params,
  });
  return data;
};

export const getFile = async (fileId) => {
  let { data } = await apiClient.get(`/file/${fileId}`);
  return data.data.meta;
};

export const changeStatus = async (fileId, status, comment) => {
  const { data } = await apiClient.post(`/file/${fileId}`, {
    status,
    comment,
  });
  return data;
};

export const getTranscript = async (fileId) => {
  const { data } = await apiClient.get(`/transcript/${fileId}`);
  return data.data;
};

export const saveTranscript = async (fileId, payload) => {
  const { data } = await apiClient.post(`/transcript/${fileId}`, payload);
  return data;
};

export const exportToExcel = async ({ status, annotator, ...filters }) => {
  let url = `/files/export/`;
  // let queries = new URLSearchParams({
  //   status,
  //   annotator,
  //   ...filters,
  // }).toString();
  // window.open(`${window.domain}/${url}?${queries}`, "_blank");
  const result = await apiClient.post(
    url,
    { status, annotator, ...filters },
    { responseType: "blob" }
  );
  let type = result.headers["content-type"];
  let blob = new Blob([result.data], { type: type });
  let filename = `file.xlsx`;

  let URL = window.URL || window.webkitURL;
  let downloadUrl = URL.createObjectURL(blob);

  let a = document.createElement("a");
  if (typeof a.download === "undefined") {
    window.location = downloadUrl;
  } else {
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  setTimeout(function () {
    URL.revokeObjectURL(downloadUrl);
  }, 100); // cleanup

  return;
};

export const tagForModel = async (file_id, action) => {
  let { data } = await apiClient.post("/files", {
    action,
    file_ids: [file_id],
  });
  return data;
};
