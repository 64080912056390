export const statuses = {
  all: {
    title: "Все файлы",
    pluralTitle: "Все файлы"
  },
  0: {
    title: "Новый",
    pluralTitle: "Новые"
  },
  1: {
    title: "В работе",
    pluralTitle: "В работе"
  },
  2: {
    title: "Размечен",
    pluralTitle: "Размеченные"
  },
  3: {
    title: "На верификации",
    pluralTitle: "На верификации"
  },
  4: {
    title: "Верифицирован",
    pluralTitle: "Верифицированные"
  },
  5: {
    title: "Оплачено",
    pluralTitle: "Оплаченные"
  },
  6: {
    title: "Архивированные",
    pluralTitle: "Архивированные"
  }
};
export function getStatus(state) {
  return statuses[state] || state;
}